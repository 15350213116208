import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { BsGithub } from "react-icons/bs";
import { CgWebsite } from "react-icons/cg";

function ProjectCards(props) {
  return (
    <Card className='project-card-view'>
      <Card.Img variant='top' src={props.imgPath} alt='card-img' />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>

        {/* Render GitHub button if GitHub link is provided */}
        {props.ghLink && (
          <Button variant='primary' href={props.ghLink} target='_blank'>
            <BsGithub /> &nbsp; GitHub (Frontend)
          </Button>
        )}

        {props.ghLink2 && (
          <Button variant='primary' href={props.ghLink2} target='_blank'>
            <BsGithub /> &nbsp; GitHub (Backend)
          </Button>
        )}

        {/* Render Demo button if Demo link is provided */}
        {props.demoLink && (
          <Button
            variant='primary'
            href={props.demoLink}
            target='_blank'
            style={{ marginLeft: "10px" }}
          >
            <CgWebsite /> &nbsp; Live Demo
          </Button>
        )}
      </Card.Body>
    </Card>
  );
}
export default ProjectCards;
