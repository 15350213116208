import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import genetically from "../../Assets/Projects/Genetically.png";
import Go from "../../Assets/Projects/Gostudy.png";
import Tour from "../../Assets/Projects/Tour.png";
import zentro from "../../Assets/Projects/zento.png";
import Particle from "../Particle";
import ProjectCard from "./ProjectCards";

function Projects() {
  return (
    <Container fluid className='project-section'>
      <Particle />
      <Container>
        <h1 className='project-heading'>
          My Recent <strong className='purple'>Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className='project-card'>
            <ProjectCard
              imgPath={genetically}
              isBlog={false}
              title='Genetically'
              description='Genetically, Solution for Anchestry DNA Testing. It is a platform where users can find their ancestors and their DNA. It is a full-stack web application built with React.js, Material UI & Firebase.'
              demoLink='https://cognitive-jade.vercel.app/'
            />
          </Col>

          <Col md={4} className='project-card'>
            <ProjectCard
              imgPath={Tour}
              isBlog={false}
              title='TourBD'
              description='TourBD is a platform where users can find their desired places to visit in Bangladesh. It is a MERN STACK web application built with MongoDB, Express.js,  React.js, Node.js and many more libraries.'
              ghLink='https://github.com/tushanafnan/TourBD-Front-End'
              ghLink2='https://github.com/tushanafnan/TourBD-Back-End'
            />
          </Col>

          <Col md={4} className='project-card'>
            <ProjectCard
              imgPath={Go}
              isBlog={false}
              title='GoStudy'
              description='GoStudy is a platform where students can find their desired courses and can learn from the best teachers. It is a client sided web application built with React.js, Bootstrap, Rest APIs & Firebase Authentication .'
              ghLink='https://github.com/tushanafnan/Go-Study'
            />
          </Col>

          <Col md={4} className='project-card'>
            <ProjectCard
              imgPath={zentro}
              isBlog={false}
              title='Zentro'
              description='Zentro is a cutting-edge cloud kitchen system developed by Team Anonymous, designed to deliver professional and productivity-driven food ordering experiences. Built With Vanilla JavaScript, HTML/BootStrap'
              ghLink='https://github.com/tushanafnan/Zentro'
              demoLink='https://zentrokitchen.netlify.app/'
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
