import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  AiFillGithub,
  AiFillInstagram,
  AiOutlineTwitter,
} from "react-icons/ai";
import { FaFacebook, FaLinkedinIn } from "react-icons/fa";
import Tilt from "react-parallax-tilt";
import myImg from "../../Assets/avatar.svg";

function Home2() {
  return (
    <Container fluid className='home-about-section' id='about'>
      <Container>
        <Row>
          <Col md={8} className='home-about-description'>
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className='purple'> INTRODUCE </span> MYSELF
            </h1>
            <p className='home-about-body'>
              I'm a Software Engineer with a passion for building innovative
              solutions and exploring emerging technologies, particularly in the
              fields of Blockchain, JavaScript, Python, Java, Data Structures,
              and Algorithms, and Object-Oriented Programming (OOP).
              <br />
              <br />
              Proficient in <b className='purple'>JavaScript</b>,{" "}
              <b className='purple'>Python</b>, and{" "}
              <b className='purple'>Java</b>, I specialize in designing and
              developing robust and scalable software systems that solve complex
              problems.
              <br />
              <br />
              With expertise in <b className='purple'>Blockchain</b>{" "}
              technologies such as
              <b className='purple'> Ethereum</b> and{" "}
              <b className='purple'>Smart Contracts</b>, I'm passionate about
              leveraging decentralized solutions to drive innovation and
              transparency in various industries.
              <br />
              <br />I excel in implementing efficient{" "}
              <b className='purple'>Data Structures</b> and crafting optimized{" "}
              <b className='purple'>Algorithms</b> to solve computational
              challenges and improve system performance.
              <br />
              <br />
              Experienced in{" "}
              <b className='purple'>Object-Oriented Programming (OOP)</b>{" "}
              principles, I apply best practices to design modular and
              maintainable codebases, ensuring scalability and extensibility.
            </p>
          </Col>

          <Col md={4} className='myAvtar'>
            <Tilt>
              <img src={myImg} className='img-fluid' alt='avatar' />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className='home-about-social'>
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className='purple'>connect </span>with me
            </p>
            <ul className='home-about-social-links'>
              <li className='social-icons'>
                <a
                  href='https://github.com/tushanafnan'
                  target='_blank'
                  rel='noreferrer'
                  className='icon-colour  home-social-icons'
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className='social-icons'>
                <a
                  href='https://twitter.com/tus_af'
                  target='_blank'
                  rel='noreferrer'
                  className='icon-colour  home-social-icons'
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className='social-icons'>
                <a
                  href='https://www.linkedin.com/in/tushanafnan/'
                  target='_blank'
                  rel='noreferrer'
                  className='icon-colour  home-social-icons'
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className='social-icons'>
                <a
                  href='https://www.instagram.com/tus.af'
                  target='_blank'
                  rel='noreferrer'
                  className='icon-colour home-social-icons'
                >
                  <AiFillInstagram />
                </a>
              </li>
              <li className='social-icons'>
                <a
                  href='https://www.facebook.com/tushsss'
                  target='_blank'
                  rel='noreferrer'
                  className='icon-colour home-social-icons'
                >
                  <FaFacebook />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
