import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className='quote-card-view'>
      <Card.Body>
        <blockquote className='blockquote mb-0'>
          <p style={{ textAlign: "justify", fontSize: "1.1em" }}>
            Hi Everyone, I'm{" "}
            <span className='purple'>Md.Sultanul Arefin Afnan</span>, also known
            as&nbsp;
            <span className='purple'>Tushan Afnan</span>. I'm a passionate and
            dedicated <span className='purple'>Software Engineer</span> based in{" "}
            <span className='purple'>Dalian, China</span>.
            <br />
            <br />
            Currently, I am honing my skills in{" "}
            <span className='purple'>Web Development</span> and{" "}
            <span className='purple'>Full-Stack Development</span> by taking on
            challenging projects on platforms like Fiverr. I specialize in
            crafting responsive and user-friendly web applications using modern
            technologies such as <span className='purple'>React.js</span>,{" "}
            <span className='purple'>Node.js</span>, and{" "}
            <span className='purple'>Express.js</span>.
            <br />
            <br />
            Alongside my freelancing work, I am pursuing a Bachelor of Science
            degree in{" "}
            <span className='purple'>Computer Science & Technology</span> at
            Dalian Polytechnic University, where I am enhancing my knowledge of
            software engineering principles and computer science fundamentals.
            <br />
            <br />I have a strong inclination towards real-world problem-solving
            and enjoy tackling complex challenges by applying{" "}
            <span className='purple'>data structures</span> and{" "}
            <span className='purple'>algorithms</span>. Exploring different
            algorithmic techniques and optimizing solutions to improve
            efficiency is a part of my coding journey.
            <br />
            <br />
            Additionally, I'm actively involved in{" "}
            <span className='purple'>Blockchain Development</span>, where I work
            on various blockchain projects and smart contracts. I also have
            experience in <span className='purple'>Social Media</span> and{" "}
            <span className='purple'>Community Management</span>, where I engage
            with online communities and manage social media platforms for
            projects and organizations.
            <br />
            <br />
            When I'm not coding, you can find me engaging in various activities:
          </p>
          <ul>
            <li className='about-activity'>
              <ImPointRight /> Exploring new technologies and frameworks
            </li>
            <li className='about-activity'>
              <ImPointRight /> Contributing to open-source projects
            </li>
            <li className='about-activity'>
              <ImPointRight /> Learning about blockchain and decentralized
              technologies
            </li>
            <li className='about-activity'>
              <ImPointRight /> Sharing my knowledge through writing and teaching
            </li>
            <li className='about-activity'>
              <ImPointRight /> Travelling and experiencing diverse cultures
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Life is a journey, and coding is my compass!"
          </p>
          <footer className='blockquote-footer'>Tushan Afnan</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
