import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { AiOutlineDownload } from "react-icons/ai";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import Particle from "../Particle";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function ResumeNew() {
  const [width, setWidth] = useState(window.innerWidth);
  const [pdfSource, setPdfSource] = useState(null);

  useEffect(() => {
    setWidth(window.innerWidth);
    loadPdf();
  }, []);

  const loadPdf = async () => {
    try {
      // Directly importing PDF file
      const pdf = await import("../../Assets/Afnan_Resume.pdf");
      setPdfSource(pdf.default);
    } catch (error) {
      console.error("Error loading PDF:", error);
    }
  };

  return (
    <div>
      <Container fluid className='resume-section'>
        <Particle />
        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button
            variant='primary'
            href={pdfSource}
            target='_blank'
            style={{ maxWidth: "250px" }}
          >
            <AiOutlineDownload />
            &nbsp;Download CV
          </Button>
        </Row>

        <Row className='resume' style={{ justifyContent: "center" }}>
          {pdfSource && (
            <Document file={pdfSource} className='resume-document'>
              <Page pageNumber={1} scale={width > 786 ? 1.0 : 0.6} />
            </Document>
          )}
        </Row>

        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button
            variant='primary'
            href={pdfSource}
            target='_blank'
            style={{ maxWidth: "250px" }}
          >
            <AiOutlineDownload />
            &nbsp;Download CV
          </Button>
        </Row>
      </Container>
    </div>
  );
}

export default ResumeNew;
